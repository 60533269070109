import { Router } from "@reach/router";
import Posts from './components/posts'
import './App.css';

function App() {
    return (
        <Router>
            <Posts path="/" />
        </Router>
    );
}

export default App;
